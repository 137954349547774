import React from "react";

import { useTranslation } from "react-i18next";

import vector from '../../img/home/Vector.png'

function Refer () {

    const { t } = useTranslation();
    
    return(
        <div className="relative overflow-hidden pt-[80px] xl:pt-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col md:flex-row">
                <div className="md:w-1/3 bg-[#000000] flex flex-col justify-center px-[20px] xl:px-[40px] h-[265px] xl:h-[426px]">
                    <p className="manrope text-[16px] xl:text-[32px] text-white leading-[1.2]">{t('home.6')}</p>
                    <p className="text-[14px] xl:text-[16px] text-[#FFFFFFB2] font-[300] roboto mt-[20px]">{t('home.7')}</p>
                </div>
                <div className="bg-[#B30101] md:w-2/3 flex flex-col justify-center px-[20px] xl:px-[60px] h-[265px] xl:h-[426px]">
                    <p className="manrope text-[16px] xl:text-[32px] text-white leading-[1.2]">{t('home.8')}</p>
                    <p className="text-[14px] xl:text-[16px] text-[#FFFFFFB2] font-[300] roboto mt-[20px]">{t('home.9')}</p>
                </div>
            </div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col md:flex-row">
                <div className="md:w-2/3 flex flex-col justify-center h-[265px] xl:h-[426px]">
                  <img src={vector} alt="" className="w-full h-full object-cover"/>
                </div>
                <div className="md:w-1/3 bg-[#000000] flex flex-col justify-center px-[20px] xl:px-[40px] h-[265px] xl:h-[426px]">
                    <p className="manrope text-[16px] xl:text-[32px] text-white leading-[1.2]">{t('home.6')}</p>
                    <p className="text-[14px] xl:text-[16px] text-[#FFFFFFB2] font-[300] roboto mt-[20px]">{t('home.7')}</p>
                </div>
            </div>
        </div>
    );
}

export default Refer;