import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import sw1 from '../../img/tradingproducts/sw(01).png'
import sw2 from '../../img/tradingproducts/sw(02).png'
import sw3 from '../../img/tradingproducts/sw(03).png'
import sw4 from '../../img/tradingproducts/sw(04).png'
import sw5 from '../../img/tradingproducts/sw(05).png'

import Fade from 'react-reveal/Fade'

const data = [
    {
        title: 'trading.products.5',
        img: sw1,
        text1: 'trading.products.10',  
        text2: 'trading.products.11',
        tableData: [
            {
                symbol: 'BTC/USD',
                fullName: '1 Lot = 1 BTC',
                contractSize: 1,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 1,
                profitCalculationMode: 'CFD',
                swapType: 'Interest',
                swapLong: -18,
                swapShort: -12,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'ETH',
                fullName: '1 Lot = 100 ETH',
                contractSize: 100,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 2,
                profitCalculationMode: 'CFD',
                swapType: 'Interest',
                swapLong: -25,
                swapShort: -12,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'BATUSD',
                fullName: 'Basic Attention Toker vs US Dollar',
                contractSize: 10000,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 5,
                profitCalculationMode: 'CFD',
                swapType: 'Interest',
                swapLong: -20,
                swapShort: -20,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'DOGUSD',
                fullName: 'Doge vs US Dollar',
                contractSize: 10000,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 5,
                profitCalculationMode: 'CFD',
                swapType: 'Interest',
                swapLong: -20,
                swapShort: -20,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'DOTUSD',
                fullName: 'Polkadot vs US Dollar',
                contractSize: 100,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 3,
                profitCalculationMode: 'CFD',
                swapType: 'Interest',
                swapLong: -20,
                swapShort: -20,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'ETCUSD',
                fullName: 'Ethereum Classic vs US Dollar',
                contractSize: 100,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 3,
                profitCalculationMode: 'CFD',
                swapType: 'Interest',
                swapLong: -20,
                swapShort: -20,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'IOTUSD',
                fullName: 'IOTA vs US Dollar',
                contractSize: 1000,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 5,
                profitCalculationMode: 'CFD',
                swapType: 'Interest',
                swapLong: -20,
                swapShort: -20,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'LNKUSD',
                fullName: 'Chainlink vs US Dollar',
                contractSize: 100,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 3,
                profitCalculationMode: 'CFD',
                swapType: 'Interest',
                swapLong: -20,
                swapShort: -20,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'NEOUSD',
                fullName: 'NEO vs US Dollar',
                contractSize: 100,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 3,
                profitCalculationMode: 'CFD',
                swapType: 'Interest',
                swapLong: -20,
                swapShort: -20,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'XMRUSD',
                fullName: 'Monero vs US Dollar',
                contractSize: 100,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 2,
                profitCalculationMode: 'CFD',
                swapType: 'Interest',
                swapLong: -20,
                swapShort: -20,
                threeDaySwaps: 'Friday',
            },
        ],
    },
    {
        title: 'trading.products.6',  
        img: sw2,
        text1: 'trading.products.12',  
        text2: 'trading.products.13',
        textwhat1: 'trading.products.20',
        textwhat2: 'trading.products.21',
        tableData: [
            {
                symbol: 'DOWJONES',
                fullName: 'US Dow Jones 30 Spot Index CFD',
                contractSize: 1,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 2,
                profitCalculationMode: 'CFD',
                swapType: 'Margin Currency',
                swapLong: -6.8716,
                swapShort: 2.1485,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'S&P500',
                fullName: 'SPOT S&P500 E-mini',
                contractSize: 1,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 2,
                profitCalculationMode: 'CFD',
                swapType: 'Margin Currency',
                swapLong: -0.8396,
                swapShort: 0.2625,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'Nikkei225',
                fullName: 'Japan 225 Spot Index CFD (Nikkei)',
                contractSize: 1,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 2,
                profitCalculationMode: 'CFD',
                swapType: 'Margin Currency',
                swapLong: -1.9748,
                swapShort: -2.2513,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'NASDAQ',
                fullName: 'SPOT NASDAQ 100 E-mini',
                contractSize: 1,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 2,
                profitCalculationMode: 'CFD',
                swapType: 'Margin Currency',
                swapLong: -2.6702,
                swapShort: 0.8349,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'AUS200',
                fullName: 'Australia 200 Spot Index CFD',
                contractSize: 1,
                marginCurrency: 'AUD',
                execution: 'Market',
                digit: 2,
                profitCalculationMode: 'CFD',
                swapType: 'Margin Currency',
                swapLong: -1.1889,
                swapShort: 0.1564,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'ChinaA50',
                fullName: 'Chinaa 50 Spot Index CFD',
                contractSize: 1,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 2,
                profitCalculationMode: 'CFD',
                swapType: 'Margin Currency',
                swapLong: -1.17603,
                swapShort: 0.2155,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'ChinaHShar',
                fullName: 'China HShar Spot Index CFD',
                contractSize: 1,
                marginCurrency: 'HKD',
                execution: 'Market',
                digit: 2,
                profitCalculationMode: 'CFD',
                swapType: 'Margin Currency',
                swapLong: -1.1286,
                swapShort: 0.0734,
                threeDaySwaps: 'Friday',
            },
        ]
    },
    {
        title: 'trading.products.7', 
        img: sw3,
        text1: 'trading.products.14',  
        text2: 'trading.products.15', 
        textwhat1: 'trading.products.22',
        textwhat2: 'trading.products.23',
        tableData: [
            {
                symbol: 'GOLD',
                fullName: 'Gold v US Dollar',
                contractSize: 100,
                marginCurrency: 'USD',
                execution: 'Instant',
                digit: 2,
                profitCalculationMode: 'CFD',
                swapType: 'Dollars',
                swapLong: -30.8412,
                swapShort: 17.1382,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'SILVER',
                fullName: 'SPOT Silver Ounce vs US Dollar',
                contractSize: 5000,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 3,
                profitCalculationMode: 'CFD',
                swapType: 'Dollars',
                swapLong: -19.351,
                swapShort: 13.8904,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'XAUUSD',
                fullName: 'Gold v US Dollar BMarket',
                contractSize: 100,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 2,
                profitCalculationMode: 'CFD',
                swapType: 'Dollars',
                swapLong: -30.8412,
                swapShort: 17.1382,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'GOLDEURO',
                fullName: 'SPOT Gold Once vs Euro',
                contractSize: 100,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 2,
                profitCalculationMode: 'CFD',
                swapType: 'Dollars',
                swapLong: -18.7712,
                swapShort: 10.3735,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'SILVEREURO',
                fullName: 'SPOT Silver Once vs Euro',
                contractSize: 5000,
                marginCurrency: 'AUD',
                execution: 'Market',
                digit: 3,
                profitCalculationMode: 'CFD',
                swapType: 'Dollars',
                swapLong: -11.7758,
                swapShort: 6.5077,
                threeDaySwaps: 'Friday',
            },
        ]
    },
    {
        title: 'trading.products.8', 
        img: sw4,
        text1: 'trading.products.16',  
        text2: 'trading.products.17', 
        textwhat1: 'trading.products.24',
        textwhat2: 'trading.products.25',
        tableData: [
            {
                symbol: 'USDJPY',
                fullName: 'US Dollar vs Japanese Yen',
                contractSize: 100000,
                marginCurrency: 'USD',
                execution: 'Instant',
                digit: 3,
                profitCalculationMode: 'Forex',
                swapType: 'Points',
                swapLong: 15.0551,
                swapShort: -120.86,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'GBPUSD',
                fullName: 'British Pound vs US Dollar',
                contractSize: 100000,
                marginCurrency: 'GBP',
                execution: 'Instant',
                digit: 5,
                profitCalculationMode: 'Forex',
                swapType: 'Points',
                swapLong: -5.1026,
                swapShort: -0.6559,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'USDCAD',
                fullName: 'US Dollar vs Canadian',
                contractSize: 100000,
                marginCurrency: 'USD',
                execution: 'Instant',
                digit: 5,
                profitCalculationMode: 'Forex',
                swapType: 'Points',
                swapLong: -5.2,
                swapShort: -1.65,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'EURUSD',
                fullName: 'Euro vs US Dollar',
                contractSize: 100000,
                marginCurrency: 'EUR',
                execution: 'Instant',
                digit: 5,
                profitCalculationMode: 'Forex',
                swapType: 'Points',
                swapLong: -9.12,
                swapShort: 2.96,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'AUDUSD',
                fullName: 'Australian vs US Dollar',
                contractSize: 100000,
                marginCurrency: 'AUD',
                execution: 'Instant',
                digit: 5,
                profitCalculationMode: 'Forex',
                swapType: 'Points',
                swapLong: -5.688,
                swapShort: -4.33,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'NZDUSD',
                fullName: 'New Zeland Dollar vs US Dollar',
                contractSize: 100000,
                marginCurrency: 'NZD',
                execution: 'Instant',
                digit: 5,
                profitCalculationMode: 'Forex',
                swapType: 'Points',
                swapLong: -2.4899,
                swapShort: -3.5667,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'USDCHF',
                fullName: 'US Dollar vs Swiss Franc',
                contractSize: 100000,
                marginCurrency: 'USD',
                execution: 'Instant',
                digit: 5,
                profitCalculationMode: 'Forex',
                swapType: 'Points',
                swapLong: 6.99,
                swapShort: -12.75,
                threeDaySwaps: 'Friday',
            },
        ]
    },
    {
        title: 'trading.products.9', 
        img: sw5,
        text1: 'trading.products.18',  
        text2: 'trading.products.19', 
        textwhat1: 'trading.products.26',
        textwhat2: 'trading.products.27',
        tableData: [
            {
                symbol: 'USOIL',
                fullName: 'West Texas Intermediate Spot',
                contractSize: 1000,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 3,
                profitCalculationMode: 'CFD',
                swapType: 'Dollars',
                swapLong: 2.3,
                swapShort: -7.6,
                threeDaySwaps: 'Friday',
            },
            {
                symbol: 'NATGAS',
                fullName: 'Natural Gas (US) Spot Fix Spread',
                contractSize: 10000,
                marginCurrency: 'USD',
                execution: 'Market',
                digit: 3,
                profitCalculationMode: 'CFD',
                swapType: 'Dollars',
                swapLong: -6,
                swapShort: 1,
                threeDaySwaps: 'Friday',
            },
        ]
    },
]

function Switcher () {

    const { t } = useTranslation();

    const [active, setActive] = useState(0);

    useEffect(() => {
        // Preload images
        data.forEach(slide => {
            const img = new Image();
            img.src = slide.img;
        });
    }, []);
      
    return(
        <div className="relative overflow-hidden pt-[80px] xl:pt-[130px] pb-[80px] xl:pb-[160px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-wrap justify-between gap-[20px]">
                {data.map((slide, index) => (
                    <div className={`cursor-pointer flex items-center justify-center flex-grow px-[20px] py-[20px] transition-all ${active === index ? 'bg-[#B30101]' : 'bg-[#ECECEC]'}`} key={index} onClick={() => setActive(index)}>
                        <p className={`manrope text-[16px] xl:text-[20px] font-[500] leading-[1.2] transition-all ${active === index ? 'text-white' : 'text-[#B30101]'}`}>{t(slide.title)}</p>
                    </div>
                ))}
            </div>
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] flex flex-col md:flex-row justify-between mt-[20px] xl:mt-[40px]">
                <div className="xl:w-[480px] flex flex-col justify-between">
                    <p className="text-[16px] xl:text-[32px] manrope leading-[1.2] font-[500] text-[#B30101]">[</p>
                    <div className="px-[30px]">
                        <p className="text-[20px] xl:text-[40px] manrope font-[500] text-[#B30101] leading-[1.2]">{t(data[active].title)}</p>
                        <p className="mt-[20px] text-[16px] xl:text-[20px] roboto text-[#000000B2]">{t(data[active].text1)}</p>
                        <p className="roboto text-[14px] xl:text-[16px] font-[300] text-[#000000B2] mt-[40px]">{t(data[active].text2)}</p>
                    </div>
                    <p className="text-[16px] xl:text-[32px] manrope leading-[1.2] font-[500] text-[#B30101] ml-auto">]</p>
                </div>
                <div className="relative overflow-hidden mt-[20px] xl:mt-0">
                    <p className="absolute text-white z-50 bottom-0 2xl:bottom-[-50px] right-0 2xl:right-[-50px] lowercase font-[800] text-[5vw]">{t(data[active].title)}</p>
                    <Fade key={active}><img src={data[active].img} alt="" /></Fade>
                </div>
            </div>
            {data[active].textwhat1 || data[active].textwhat2 ? (
                <div className="max-w-[1280px] xl:mx-auto mx-[20px] flex flex-col xl:flex-row justify-between mt-[40px] xl:mt-[80px]">
                    <p className="manrope text-[16px] xl:text-[32px] font-[500] leading-[1.2] text-black">
                        {t(data[active].textwhat1)}
                    </p>
                    <p className="xl:max-w-[703px] roboto text-[14px] xl:text-[16px] font-[300] text-[#000000B2] mt-[20px] xl:mt-0">
                        {t(data[active].textwhat2)}
                    </p>
                </div>
            ) : null}
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] overflow-x-auto mt-[20px] xl:mt-[100px]">
                <table className="min-w-full bg-white border-gray-200">
                    <thead>
                        <tr>
                            <th className="px-4 py-2 border-b roboto text-[16px] font-[600] text-[#000000B2]">{t('trading.products.28')}</th>
                            <th className="px-4 py-2 border-b roboto text-[16px] font-[600] text-[#000000B2]">{t('trading.products.29')}</th>
                            <th className="px-4 py-2 border-b roboto text-[16px] font-[600] text-[#000000B2]">{t('trading.products.30')}</th>
                            <th className="px-4 py-2 border-b roboto text-[16px] font-[600] text-[#000000B2]">{t('trading.products.31')}</th>
                            <th className="px-4 py-2 border-b roboto text-[16px] font-[600] text-[#000000B2]">{t('trading.products.32')}</th>
                            <th className="px-4 py-2 border-b roboto text-[16px] font-[600] text-[#000000B2]">{t('trading.products.33')}</th>
                            <th className="px-4 py-2 border-b roboto text-[16px] font-[600] text-[#000000B2]">{t('trading.products.34')}</th>
                            <th className="px-4 py-2 border-b roboto text-[16px] font-[600] text-[#000000B2]">{t('trading.products.35')}</th>
                            <th className="px-4 py-2 border-b roboto text-[16px] font-[600] text-[#000000B2]">{t('trading.products.36')}</th>
                            <th className="px-4 py-2 border-b roboto text-[16px] font-[600] text-[#000000B2]">{t('trading.products.37')}</th>
                            <th className="px-4 py-2 border-b roboto text-[16px] font-[600] text-[#000000B2]">{t('trading.products.38')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data[active].tableData.map((row, index) => (
                            <tr key={index} className="hover:bg-gray-100">
                                <td className="px-4 py-2 border-b roboto text-[16px] text-[#000000B2] text-center">{row.symbol}</td>
                                <td className="px-4 py-2 border-b roboto text-[16px] text-[#000000B2] text-center">{row.fullName}</td>
                                <td className="px-4 py-2 border-b roboto text-[16px] text-[#000000B2] text-center">{row.contractSize}</td>
                                <td className="px-4 py-2 border-b roboto text-[16px] text-[#000000B2] text-center">{row.marginCurrency}</td>
                                <td className="px-4 py-2 border-b roboto text-[16px] text-[#000000B2] text-center">{row.execution}</td>
                                <td className="px-4 py-2 border-b roboto text-[16px] text-[#000000B2] text-center">{row.digit}</td>
                                <td className="px-4 py-2 border-b roboto text-[16px] text-[#000000B2] text-center">{row.profitCalculationMode}</td>
                                <td className="px-4 py-2 border-b roboto text-[16px] text-[#000000B2] text-center">{row.swapType}</td>
                                <td className="px-4 py-2 border-b roboto text-[16px] text-[#000000B2] text-center">{row.swapLong}</td>
                                <td className="px-4 py-2 border-b roboto text-[16px] text-[#000000B2] text-center">{row.swapShort}</td>
                                <td className="px-4 py-2 border-b roboto text-[16px] text-[#000000B2] text-center">{row.threeDaySwaps}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Switcher;