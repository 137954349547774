import React from "react";

import { useTranslation, Trans } from "react-i18next";

import shadow from '../../img/home/shadow_phone.png'

import note from '../../img/home/note.png'

import Button from '../Button'

import Fade from 'react-reveal/Fade'

function Main () {

    const { t } = useTranslation();
    
    return(
        <div className="relative overflow-hidden">
            <div className="xl:h-[763px] bg-black">
                <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col xl:flex-row justify-between">
                    <div>
                        <p className="text-[20px] xl:text-[40px] manrope font-[500] leading-[1.2] text-white mt-[40px] xl:mt-[110px]">{t('home.2')}</p>
                        <div className="absolute left-0 w-[100%-20px] xl:w-[55%] h-[54px] xl:h-[104px] bg-[#B30101] mt-[5px] flex items-center justify-end p-[20px] z-[500]">
                            <p className="text-[36px] xl:text-[60px] 2xl:text-[80px] manrope font-[500] leading-[1.2] text-white text-nowrap">{t('home.3')}</p>
                        </div>
                        <p className="text-[16px] xl:text-[20px] roboto font-[400] leading-[1.5] text-[#FFFFFFB2] mt-[80px] xl:mt-[125px]">{t('home.4')}</p>
                        <Button className="mt-[20px] xl:mt-[80px]"/>
                    </div>
                    <div className="mt-[40px] xl:mt-0">
                        <Fade right>
                            <img src={note} alt="" className="xl:w-[817px] z-50 relative"/>
                        </Fade>
                    </div>
                </div>
                <Fade bottom>
                <div className="absolute left-[-50px] bottom-[-100px] md:bottom-[-150px] xl:bottom-[-190px]">
                    <p className="text-white manrope text-[200px] md:text-[300px] xl:text-[400px] font-[800] text-main">{t('home.1')}</p>
                </div>
                </Fade>
                <img src={shadow} alt=""  className="absolute bottom-0 translate-x-1/2"/>
            </div>
        </div>
    );
}

export default Main;