import React from "react";

import { useTranslation } from "react-i18next";

function WhatAreDaily () {

    const { t } = useTranslation();
    
    return(
        <div className="relative overflow-hidden pt-[80px] xl:pt-[120px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px]">
                <p className="text-[32px] xl:text-[48px] manrope font-[500] text-black leading-[1.2] xl:max-w-[544px]">{t('tradingtools.4')}</p>
                <div className="flex flex-col md:flex-row justify-between mt-[20px] xl:mt-[40px]">
                    <div className="max-w-[562px] flex flex-col gap-y-[20px]">
                        <p className="text-[#000000B2] roboto font-[300] text-[14px] xl:text-[16px]">{t('tradingtools.5')}</p>
                        <p className="text-[#000000B2] roboto font-[300] text-[14px] xl:text-[16px]">{t('tradingtools.6')}</p>
                        <p className="text-[#000000B2] roboto font-[300] text-[14px] xl:text-[16px]">{t('tradingtools.7')}</p>
                    </div>
                    <div className="max-w-[562px] mt-[20px] xl:mt-0 flex flex-col gap-y-[20px]">
                        <p className="text-[#000000B2] roboto font-[400] text-[16px] xl:text-[20px]">{t('tradingtools.8')}</p>
                        <div className="bg-[#B30101] h-[168px] flex gap-[20px] px-[20px] py-[40px] items-start">
                            <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M16.2529 10.1564L1.01776 18.9524L1.01777 1.36038L16.2529 10.1564Z" fill="white"/>
                            </svg>
                            <p className="text-white manrope text-[16px] xl:text-[20px] font-[500]">{t('tradingtools.9')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhatAreDaily;