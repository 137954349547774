import React from "react";

import { useTranslation } from "react-i18next";

function Button ( { className } ) {

    const { t } = useTranslation();
      
    return(
        <div className={className}>
            <a href="https://user.linearc.info/">
                <div className="button_other flex cursor-pointer">
                    <p className="text-white uppercase manrope font-[700] text-[16px]">{t('home.5')}</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M16.2527 10.1568L1.01752 18.9528L1.01752 1.36075L16.2527 10.1568Z" fill="white"/>
                    </svg>
                </div>
            </a>
        </div>
    );
}

export default Button;