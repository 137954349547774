import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import m from '../../img/AboutUs/main.png'

function Main () {

    const { t } = useTranslation();
    
    return(
        <div className="relative overflow-hidden">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px]">
                <div className="flex flex-col xl:flex-row justify-between mt-[40px] xl:mt-[120px]">
                    <div>
                        <p className="spanred leading-[1.2] font-[500] text-[40px] xl:text-[80px] manrope max-w-[525px]"><Trans>{t('aboutus.1')}</Trans></p>
                        <p className="mt-[20px] xl:mt-[40px] roboto text-[14px] xl:text-[16px] text-[#000000B2] xl:max-w-[562px] font-[300]">{t('aboutus.2')}</p>
                        <p className="mt-[20px] roboto text-[14px] xl:text-[16px] text-[#000000B2] xl:max-w-[562px] font-[300]">{t('aboutus.3')}</p>
                        <p className="mt-[20px] roboto text-[14px] xl:text-[16px] text-[#000000B2] xl:max-w-[562px] font-[300]">{t('aboutus.4')}</p>
                    </div>
                    <div className="mt-[20px] xl:mt-0">
                        <img src={m} alt="" className="mx-auto md:mx-0"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;