import React from "react";

import { useTranslation, Trans } from "react-i18next";

import vector from '../../img/home/Vector.png'

function GroundBreaking () {

    const { t } = useTranslation();
    
    return(
        <div className="relative overflow-hidden pt-[80px]">
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col md:flex-row justify-between">
                <p className="spanred text-[20px] xl:text-[40px] manrope text-[#000000CC]"><Trans>{t('home.12')}</Trans></p>
                <p className="roboto text-[16px] xl:text-[20px] text-[#000000B2] max-w-[497px] mt-[20px] xl:mt-0">{t('home.13')}</p>
            </div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col md:flex-row justify-between pt-[80px] xl:pt-[160px] gap-[10px]">
                <div className="relative xl:w-[393px] h-[382px] xl:h-[547px] bg-[#ECECEC] overflow-hidden p-[40px] flex flex-col justify-end">
                    <p className="text-[200px] xl:text-[300px] absolute top-[-60px] left-[-50px] xl:top-[-90px] xl:left-[-50px] leading-[1] text-[#B30101] manrope font-[800] tracking-[-15px]">01</p>
                    <p className="text-[20px] xl:text-[32px] manrope leading-[1.2] text-[#000000]">{t('home.14')}</p>
                    <p className="text-[14px] xl:text-[16px] roboto leading-[1.5] text-[#000000B2] font-[300] mt-[20px]">{t('home.15')}</p>
                </div>
                <div className="relative xl:w-[393px] h-[382px] xl:h-[547px] bg-[#ECECEC] overflow-hidden p-[40px] flex flex-col">
                    <p className="text-[200px] xl:text-[300px] absolute bottom-[-60px] left-[-50px] xl: xl:bottom-[-90px] xl:left-[-50px] leading-[1] text-[#B30101] manrope font-[800] tracking-[-15px]">02</p>
                    <p className="text-[20px] xl:text-[32px] manrope leading-[1.2] text-[#000000]">{t('home.16')}</p>
                    <p className="text-[14px] xl:text-[16px] roboto leading-[1.5] text-[#000000B2] font-[300] mt-[20px]">{t('home.17')}</p>
                </div>
                <div className="relative xl:w-[393px] h-[382px] xl:h-[547px] bg-[#ECECEC] overflow-hidden p-[40px] flex flex-col justify-end">
                    <p className="text-[200px] xl:text-[300px] absolute top-[-60px] left-[-50px] xl:top-[-90px] xl:left-[-50px] leading-[1] text-[#B30101] manrope font-[800] tracking-[-15px]">03</p>
                    <p className="text-[20px] xl:text-[32px] manrope leading-[1.2] text-[#000000]">{t('home.18')}</p>
                    <p className="text-[14px] xl:text-[16px] roboto leading-[1.5] text-[#000000B2] font-[300] mt-[20px]">{t('home.19')}</p>
                </div>
            </div>
        </div>
    );
}

export default GroundBreaking;