import React from "react";

import { useTranslation, Trans } from "react-i18next";

import note from '../../img/tradingproducts/main.png'

import Button from '../Button'

import Fade from 'react-reveal/Fade'

function Main () {

    const { t } = useTranslation();
    
    return(
        <div className="relative overflow-hidden">
            <div className="xl:h-[763px] bg-black">
                <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col md:flex-row justify-between">
                    <div className="mt-[100px]">
                        <div className="flex flex-col">
                            <p className="manrope uppercase font-[500] text-[48px] xl:text-[96px] text-white leading-[1.2]">{t('trading.products.2')}</p>
                            <p className="manrope font-[500] text-[48px] xl:text-[96px] text-white leading-[1.2] xl:ml-[250px]">{t('trading.products.3')}</p>
                        </div>
                        <p className="text-[16px] xl:text-[20px] roboto font-[400] leading-[1.5] text-[#FFFFFFB2] mt-[20px] max-w-[590px]">{t('trading.products.4')}</p>
                        <Button className="mt-[20px] xl:mt-[40px]"/>
                    </div>
                    <div className="mt-[40px] xl:mt-0">
                        <Fade right>
                            <img src={note} alt="" className="w-[200px] sm:w-[300px] md:w-[400px] xl:w-[599px] relative xl:absolute bottom-0 xl:right-[100px] z-50 mx-auto"/>
                        </Fade>
                    </div>
                </div>
                <Fade bottom>
                <div className="absolute left-[-50px] bottom-[-100px] md:bottom-[-150px] xl:bottom-[-170px]">
                    <p className="text-white manrope text-[200px] md:text-[300px] xl:text-[400px] font-[800] text-main lowercase">{t('trading.products.1')}</p>
                </div>
                </Fade>
            </div>
        </div>
    );
}

export default Main;