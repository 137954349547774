import React from "react";

import { useTranslation, Trans } from "react-i18next";

import note from '../../img/tradingtools/main.png'

import Button from '../Button'

import Fade from 'react-reveal/Fade'

function Main () {

    const { t } = useTranslation();
    
    return(
        <div className="relative overflow-hidden">
            <div className="xl:h-[763px] bg-black">
                <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col md:flex-row justify-between">
                    <div className="mt-[100px]">
                        <p className="text-[20px] xl:text-[40px] manrope font-[500] leading-[1.2] text-[#FFFFFFCC]">{t('tradingtools.1')}</p>
                        <p className="text-[40px] xl:text-[80px] leading-[1.2] manrope text-white font-[500] mt-px xl:max-w-[490px]">{t('tradingtools.2')}</p>
                        <Button className="mt-[20px] xl:mt-[40px]"/>
                    </div>
                    <div className="mt-[40px] xl:mt-0">
                        <Fade right>
                            <img src={note} alt="" className="w-[400px] sm:w-[500px] md:w-[600px] xl:w-[972px] relative xl:absolute bottom-0 xl:right-[100px] z-50 mx-auto"/>
                        </Fade>
                    </div>
                </div>
                <Fade bottom>
                <div className="absolute left-[-50px] bottom-[-100px] md:bottom-[-150px] xl:bottom-[-170px]">
                    <p className="text-[#FFFFFF1A] manrope text-[200px] md:text-[300px] xl:text-[400px] font-[800] text-main lowercase">{t('tradingtools.3')}</p>
                </div>
                </Fade>
            </div>
        </div>
    );
}

export default Main;