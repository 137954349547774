import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

import Button from '../Button'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const swiperData = [
    {
        title: 'Standart',
        bg: '#000000',

        textcolor1: '#FFFFFFB2',
        textcolor2: '#FFFFFF',

        t1: "TRADE",
        t2: "USD",
        t3: "Up To 1:400",
        t4: "Unlimited",
        t5: "250 USD",
        t6: "No",
        t7: "Instant",
        t8: "Floating",
        t9: "100 %",
        t10: "20 %",
        t11: "YES",
        t12: "0",
        t13: "Forex, Metal, Energy, CFD Index, Shares, Cryptocurrency",
        t14: "Standard",
        t15: "0.01",
        t16: "No",
        t17: "YES"
    },
    {
        title: 'Silver',
        bg: '#FFFFFF1A',

        textcolor1: '#FFFFFFB2',
        textcolor2: '#FFFFFF',

        t1: "TRADE",
        t2: "USD",
        t3: "Up To 1:1000",
        t4: "No limits",
        t5: "1,000 USD",
        t6: "No",
        t7: "Instant",
        t8: "Floating",
        t9: "100 %",
        t10: "20 %",
        t11: "YES",
        t12: "Average 65",
        t13: "Forex, Metal, Energy, CFD Index, Shares, Cryptocurrency",
        t14: "Standard",
        t15: "0.01",
        t16: "No",
        t17: "YES"
    },
    {
        title: 'Gold',
        bg: '#FFFFFF33',

        textcolor1: '#FFFFFFB2',
        textcolor2: '#FFFFFF',
    
        t1: "TRADE",
        t2: "USD",
        t3: "Up To 1:200",
        t4: "Unlimited",
        t5: "5,000 USD",
        t6: "Yes",
        t7: "Market",
        t8: "Floating",
        t9: "100 %",
        t10: "50 %",
        t11: "YES",
        t12: "60",
        t13: "Forex, Metal, Energy, CFD Index, Shares, Cryptocurrency",
        t14: "Standard",
        t15: "0.01",
        t16: "YES",
        t17: "YES"
    },
    {
        title: 'Fixed Spread',
        bg: '#FFFFFF',

        textcolor1: '#000000B2',
        textcolor2: '#000000',

        t1: "TRADE",
        t2: "USD",
        t3: "Up To 1:200",
        t4: "Unlimited",
        t5: "5,000 USD",
        t6: "Yes",
        t7: "Market",
        t8: "Floating",
        t9: "100 %",
        t10: "50 %",
        t11: "YES",
        t12: "60",
        t13: "Forex, Metal, Energy, CFD Index, Shares, Cryptocurrency",
        t14: "Standard",
        t15: "0.01",
        t16: "YES",
        t17: "YES"
    },
];

function SwiperSW () {

    const { t } = useTranslation();

    const [swiperInstance, setSwiperInstance] = useState(null);

    return(
        <div className="relative overflow-hidden bg-black mt-[80px] xl:mt-[160px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] mt-[120px] xl:mt-[200px]">
                <Swiper
                    grabCursor={true}
                    slidesPerView={1}
                    loop={true}
                    spaceBetween={20}
                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                    breakpoints={{
                        1280: {
                            slidesPerView: 3,
                        },
                        600: {
                            slidesPerView: 2,
                        },
                        100: {
                            slidesPerView: 1,
                        },
                    }}
                >
                    {swiperData.map((slide, index) => (
                        <SwiperSlide key={index}>
                            <div className="flex flex-col">
                                <div className="flex items-center justify-center gap-[20px] mx-auto h-[60px] md:h-[80px] xl:h-[117px] mb-[-30px] md:mb-[-40px] xl:mb-[-60px] bg-black z-50 relative border border-white" style={{ width: "calc(100% - 60px)" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M16.2529 10.1563L1.01776 18.9523L1.01777 1.36026L16.2529 10.1563Z" fill="white"/>
                                    </svg>
                                    <p className="text-[16px] md:text-[24px] xl:text-[32px] font-[500] text-white manrope uppercase">{slide.title}</p>
                                </div>
                                <div className={`border border-white pl-[36px] pb-[50px]`} style={{ backgroundColor: slide.bg }}>
                                    <table className="mt-[80px] text-white">
                                    {Array.from({ length: 17 }, (_, i) => {

                                        const textColor2 = (i === 4) ? '#B30101' : slide.textcolor2;

                                        return (
                                            <tr key={i}>
                                                <td className="roboto text-[16px] leading-[1.4]" style={{ color: slide.textcolor1 }}>
                                                    {t(`accounttypes.${11 + i}`)}
                                                </td>
                                                <td className="roboto text-[16px] leading-[1.4] font-[500] pl-[20px] py-[3px]" style={{ color: textColor2 }}>
                                                    {slide[`t${i + 1}`]}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </table>
                                </div>
                                <Button className="mx-auto mt-[-30px]"/>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="flex gap-[20px] mt-[20px] xl:mt-[65px]">
                    <svg onClick={() => swiperInstance && swiperInstance.slidePrev()} className="cursor-pointer w-[35px] xl:w-auto" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                        <rect x="1" y="1" width="58" height="58" fill="black" stroke="white" stroke-width="2"/>
                        <path d="M14.2929 29.2929C13.9024 29.6834 13.9024 30.3166 14.2929 30.7071L20.6569 37.0711C21.0474 37.4616 21.6805 37.4616 22.0711 37.0711C22.4616 36.6805 22.4616 36.0474 22.0711 35.6569L16.4142 30L22.0711 24.3431C22.4616 23.9526 22.4616 23.3195 22.0711 22.9289C21.6805 22.5384 21.0474 22.5384 20.6569 22.9289L14.2929 29.2929ZM45 29L15 29L15 31L45 31L45 29Z" fill="white"/>
                    </svg>
                    <svg onClick={() => swiperInstance && swiperInstance.slideNext()} className="cursor-pointer w-[35px] xl:w-auto" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                        <rect x="1" y="1" width="58" height="58" fill="black" stroke="white" stroke-width="2"/>
                        <path d="M45.7071 30.7071C46.0976 30.3166 46.0976 29.6834 45.7071 29.2929L39.3431 22.9289C38.9526 22.5384 38.3195 22.5384 37.9289 22.9289C37.5384 23.3195 37.5384 23.9526 37.9289 24.3431L43.5858 30L37.9289 35.6569C37.5384 36.0474 37.5384 36.6805 37.9289 37.0711C38.3195 37.4616 38.9526 37.4616 39.3431 37.0711L45.7071 30.7071ZM15 31L45 31V29L15 29V31Z" fill="white"/>
                    </svg>
                </div>
            </div>
        </div>
    );
}

export default SwiperSW;