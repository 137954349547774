import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import a from '../../img/tradingtools/advantages.png'

const data = [
    {
        question: 'tradingtools.44',
        answer: 'tradingtools.45',
    },
    {
        question: 'tradingtools.46',
        answer: 'tradingtools.47',
    },
    {
        question: 'tradingtools.48',
        answer: 'tradingtools.49',
    },
]

function Advantages () {

    const { t } = useTranslation();

    const [openIndex, setOpenIndex] = useState(1);

    const toggleText = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    
    return(
        <div className="relative overflow-hidden pt-[80px] xl:pt-[160px] pb-[80px] xl:pb-[160px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] flex flex-col xl:flex-row justify-between">
                <div className="xl:max-w-[562px]">
                    <p className="text-[40px] xl:text-[80px] manrope font-[500] leading-[1.2] text-[#B30101]">{t('tradingtools.41')}</p>
                    <p className="mt-[10px] text-[20px] xl:text-[40px] font-[500] leading-[1.2] manrope">{t('tradingtools.42')}</p>
                    <p className="text-[#000000B2] roboto font-[300] text-[14px] xl:text-[16px] mt-[20px] xl:mt-[40px]">{t('tradingtools.43')}</p>
                    {data.map((faq, index) => (
                        <div key={index}>
                            <div className={`flex justify-between transition-all duration-300 ${openIndex === index ? 'h-auto' : 'h-[86px]'}`}>
                                <div>
                                    <p className='mt-[25px] manrope text-[20px] xl:text-[24px] text-[#000000] max-w-[200px] xl:max-w-[1280px]'>{t(faq.question)}</p>
                                    {openIndex === index && (
                                        <p className='text-[#000000B2] roboto text-[14px] xl:text-[17px] font-light mt-[12px] pb-[20px] max-w-[200px] xl:max-w-[802px]'>{t(faq.answer)}</p>
                                    )}
                                </div>
                                <div className='cursor-pointer mt-[30px] flex-shrink-0' onClick={() => toggleText(index)}>
                                    <svg className={` transition-all ${openIndex === index ? 'rotate-90' : 'rotate-0'}`} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <rect x="1" y="1" width="30" height="30" fill="#B30101" stroke="#B30101" stroke-width="2"/>
                                        <path d="M24.7071 16.7071C25.0976 16.3166 25.0976 15.6834 24.7071 15.2929L18.3431 8.92893C17.9526 8.53841 17.3195 8.53841 16.9289 8.92893C16.5384 9.31946 16.5384 9.95262 16.9289 10.3431L22.5858 16L16.9289 21.6569C16.5384 22.0474 16.5384 22.6805 16.9289 23.0711C17.3195 23.4616 17.9526 23.4616 18.3431 23.0711L24.7071 16.7071ZM8 17L24 17V15L8 15V17Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="h-[1px] w-full bg-[#000000B2]"></div>
                        </div>
                    ))}
                </div>
                <div className="mt-[20px] xl:mt-0">
                    <img src={a} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Advantages;