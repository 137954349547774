import React, { useEffect } from "react";

import Main from '../components/AboutUs/Main'
import Award from "../components/AboutUs/Award";
import OurValues from "../components/AboutUs/OurValues";

function AboutUs () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Award/>
            <OurValues/>
        </div>
    );
}

export default AboutUs;