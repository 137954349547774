import React, { useEffect } from "react";

import Main from '../components/AccountTypes/Main'
import Swiper from "../components/AccountTypes/SwiperSW";

function AccountTypes () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Swiper/>
        </div>
    );
}

export default AccountTypes;