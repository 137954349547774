import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import Fade from 'react-reveal/Fade';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import sw1 from '../../img/home/swiper/sw1.png';
import sw2 from '../../img/home/swiper/sw2.png';
import sw3 from '../../img/home/swiper/sw3.png';
import sw4 from '../../img/home/swiper/sw4.png';

const swiperData = [
    {
        type: 1,
        title: 'home.33',
        imgSrc: sw1
    },
    {
        type: 2,
        text: 'home.34',
    },
    {
        type: 1,
        title: 'home.35',
        imgSrc: sw2
    },
    {
        type: 2,
        text: 'home.36',
    },
    {
        type: 1,
        title: 'home.37',
        imgSrc: sw3
    },
    {
        type: 2,
        text: 'home.38',
    },
    {
        type: 1,
        title: 'home.39',
        imgSrc: sw4
    },
    {
        type: 2,
        text: 'home.40',
    },
];

const swiperData2 = [
    {
        title1: 'home.43',
        text1: 'home.44',
        title2: 'home.45',
        text2: 'home.46',
    },
    {
        title1: 'home.47',
        text1: 'home.48',
        title2: 'home.49',
        text2: 'home.50',
    },
    {
        title1: 'home.51',
        text1: 'home.52',
        title2: 'home.53',
        text2: 'home.54',
    },
    {
        title1: 'home.55',
        text1: 'home.56',
        title2: 'home.57',
        text2: 'home.58',
    },
    {
        title1: 'home.59',
        text1: 'home.60',
        title2: 'home.61',
        text2: 'home.62',
    },
    {
        title1: 'home.63',
        text1: 'home.64',
        title2: 'home.65',
        text2: 'home.66',
    },
    {
        title1: 'home.67',
        text1: 'home.68',
        title2: 'home.69',
        text2: 'home.70',
    },
    {
        title1: 'home.71',
        text1: 'home.72',
        title2: 'home.73',
        text2: 'home.74',
    },


    {
        title1: 'home.75',
        text1: 'home.76',
        title2: 'home.77',
        text2: 'home.78',
    },
    {
        title1: 'home.79',
        text1: 'home.80',
        title2: 'home.81',
        text2: 'home.82',
    },
    {
        title1: 'home.83',
        text1: 'home.84',
        title2: 'home.85',
        text2: 'home.86',
    },
    

]

function Services() {

    const { t } = useTranslation();
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [swiperInstance2, setSwiperInstance2] = useState(null);

    return (
        <div className="relative overflow-hidden mt-[80px] xl:mt-[160px] bg-black">
            <Fade top>
                <div className="absolute top-[-15vw]">
                    <p className="text-white font-[800] text-[24vw] leading-[1] manrope">
                        {t('home.20')}
                    </p>
                </div>
            </Fade>
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] flex flex-col xl:flex-row xl:gap-[120px] xl:mt-[350px]">
                <div className="mt-[15vw] xl:mt-0">
                    <p className="xl:max-w-[294px] manrope text-[32px] xl:text-[64px] text-white font-[500] leading-[1.2]">
                        {t('home.21')}
                    </p>
                    <div className="xl:flex gap-[20px] mt-[70px] hidden">
                        <svg onClick={() => swiperInstance && swiperInstance.slidePrev()} className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                            <rect x="1" y="1" width="58" height="58" fill="black" stroke="white" stroke-width="2"/>
                            <path d="M14.2929 29.2929C13.9024 29.6834 13.9024 30.3166 14.2929 30.7071L20.6569 37.0711C21.0474 37.4616 21.6805 37.4616 22.0711 37.0711C22.4616 36.6805 22.4616 36.0474 22.0711 35.6569L16.4142 30L22.0711 24.3431C22.4616 23.9526 22.4616 23.3195 22.0711 22.9289C21.6805 22.5384 21.0474 22.5384 20.6569 22.9289L14.2929 29.2929ZM45 29L15 29L15 31L45 31L45 29Z" fill="white"/>
                        </svg>
                        <svg onClick={() => swiperInstance && swiperInstance.slideNext()} className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                            <rect x="1" y="1" width="58" height="58" fill="black" stroke="white" stroke-width="2"/>
                            <path d="M45.7071 30.7071C46.0976 30.3166 46.0976 29.6834 45.7071 29.2929L39.3431 22.9289C38.9526 22.5384 38.3195 22.5384 37.9289 22.9289C37.5384 23.3195 37.5384 23.9526 37.9289 24.3431L43.5858 30L37.9289 35.6569C37.5384 36.0474 37.5384 36.6805 37.9289 37.0711C38.3195 37.4616 38.9526 37.4616 39.3431 37.0711L45.7071 30.7071ZM15 31L45 31V29L15 29V31Z" fill="white"/>
                        </svg>
                    </div>
                </div>
                <div className="w-full mt-[20px] xl:mt-0">
                    <Swiper
                        grabCursor={true}
                        slidesPerView={1}
                        loop={true}
                        spaceBetween={0}
                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                        breakpoints={{
                            1280: {
                                slidesPerView: 4,
                            },
                            600: {
                                slidesPerView: 2,
                            },
                            100: {
                                slidesPerView: 1,
                            },
                        }}
                    >
                        {swiperData.map((slide, index) => (
                            <SwiperSlide key={index} className="xl:!w-[310px]">
                                {slide.type === 1 ? (
                                    <div className="xl:w-[310px] bg-[#B30101] h-[287px] flex flex-col items-center pt-[40px] gap-[20px] border-[2px] border-[#FFF]">
                                        <img src={slide.imgSrc} alt={t(slide.title)} />
                                        <p className="manrope text-[16px] xl:text-[32px] text-white leading-[1.2] text-center font-[500]"><Trans>{t(slide.title)}</Trans></p>
                                        <svg className="absolute bottom-[40px] right-[20px]" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M16.2527 10.1568L1.01752 18.9528L1.01752 1.36075L16.2527 10.1568Z" fill="white"/>
                                        </svg>
                                    </div>
                                ) : (
                                    <div className="xl:w-[310px] h-[287px] border-[2px] border-[#FFF] flex flex-col justify-between p-[20px]">
                                        <p className="text-white manrope text-[16px] xl:text-[32px] font-[500] leading-[1.2]">[</p>
                                        <p className="text-[#FFFFFFB2] roboto font-[300] text-[14px] xl:text-[16px] px-[30px]">{t(slide.text)}</p>
                                        <p className="text-white manrope text-[16px] xl:text-[32px] font-[500] leading-[1.2] text-right">]</p>
                                    </div>
                                )}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div className="flex justify-between xl:hidden">
                        <svg onClick={() => swiperInstance && swiperInstance.slidePrev()} className="cursor-pointer w-[35px]" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                            <rect x="1" y="1" width="58" height="58" fill="black" stroke="white" stroke-width="2"/>
                            <path d="M14.2929 29.2929C13.9024 29.6834 13.9024 30.3166 14.2929 30.7071L20.6569 37.0711C21.0474 37.4616 21.6805 37.4616 22.0711 37.0711C22.4616 36.6805 22.4616 36.0474 22.0711 35.6569L16.4142 30L22.0711 24.3431C22.4616 23.9526 22.4616 23.3195 22.0711 22.9289C21.6805 22.5384 21.0474 22.5384 20.6569 22.9289L14.2929 29.2929ZM45 29L15 29L15 31L45 31L45 29Z" fill="white"/>
                        </svg>
                        <svg onClick={() => swiperInstance && swiperInstance.slideNext()} className="cursor-pointer w-[35px]" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                            <rect x="1" y="1" width="58" height="58" fill="black" stroke="white" stroke-width="2"/>
                            <path d="M45.7071 30.7071C46.0976 30.3166 46.0976 29.6834 45.7071 29.2929L39.3431 22.9289C38.9526 22.5384 38.3195 22.5384 37.9289 22.9289C37.5384 23.3195 37.5384 23.9526 37.9289 24.3431L43.5858 30L37.9289 35.6569C37.5384 36.0474 37.5384 36.6805 37.9289 37.0711C38.3195 37.4616 38.9526 37.4616 39.3431 37.0711L45.7071 30.7071ZM15 31L45 31V29L15 29V31Z" fill="white"/>
                        </svg>
                    </div>
                    <p className="italic text-[12px] xl:text-[14px] text-[#FFFFFFB2] roboto mt-[20px] xl:mt-[34px] max-w-[900px]">{t('home.30')}</p>
                </div>
            </div>
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] pt-[80px] xl:pt-[160px]">
                <div className="flex flex-col xl:flex-row justify-between xl:items-end">
                    <div>
                        <p className="manrope text-[32px] xl:text-[64px] text-white font-[500] leading-[1.2]">{t('home.41')}</p>
                        <p className="text-[#FFFFFFB2] max-w-[519px] roboto text-[16px] xl:text-[20px] mt-[20px]">{t('home.42')}</p>
                    </div>
                    <div className="xl:flex gap-[20px] hidden">
                        <svg onClick={() => swiperInstance2 && swiperInstance2.slidePrev()} className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                            <rect x="1" y="1" width="58" height="58" fill="black" stroke="white" stroke-width="2"/>
                            <path d="M14.2929 29.2929C13.9024 29.6834 13.9024 30.3166 14.2929 30.7071L20.6569 37.0711C21.0474 37.4616 21.6805 37.4616 22.0711 37.0711C22.4616 36.6805 22.4616 36.0474 22.0711 35.6569L16.4142 30L22.0711 24.3431C22.4616 23.9526 22.4616 23.3195 22.0711 22.9289C21.6805 22.5384 21.0474 22.5384 20.6569 22.9289L14.2929 29.2929ZM45 29L15 29L15 31L45 31L45 29Z" fill="white"/>
                        </svg>
                        <svg onClick={() => swiperInstance2 && swiperInstance2.slideNext()} className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                            <rect x="1" y="1" width="58" height="58" fill="black" stroke="white" stroke-width="2"/>
                            <path d="M45.7071 30.7071C46.0976 30.3166 46.0976 29.6834 45.7071 29.2929L39.3431 22.9289C38.9526 22.5384 38.3195 22.5384 37.9289 22.9289C37.5384 23.3195 37.5384 23.9526 37.9289 24.3431L43.5858 30L37.9289 35.6569C37.5384 36.0474 37.5384 36.6805 37.9289 37.0711C38.3195 37.4616 38.9526 37.4616 39.3431 37.0711L45.7071 30.7071ZM15 31L45 31V29L15 29V31Z" fill="white"/>
                        </svg>
                    </div>
                </div>
                <div className="mt-[20px] xl:mt-[60px]">
                    <Swiper
                        grabCursor={true}
                        slidesPerView={1}
                        loop={true}
                        spaceBetween={40}
                        onSwiper={(swiper2) => setSwiperInstance2(swiper2)}
                        breakpoints={{
                            1280: {
                                slidesPerView: 3,
                            },
                            600: {
                                slidesPerView: 2,
                            },
                            100: {
                                slidesPerView: 1,
                            },
                        }}
                    >
                        {swiperData2.map((slide, index) => (
                            <SwiperSlide key={index}>
                                <div>
                                    <div className="h-[200px] relative mx-auto">
                                        <div className="flex gap-[25px] items-center h-[60px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                <rect x="1" y="1" width="30" height="30" fill="#B30101" stroke="#B30101" stroke-width="2"/>
                                                <path d="M24.7071 16.7071C25.0976 16.3166 25.0976 15.6834 24.7071 15.2929L18.3431 8.92893C17.9526 8.53841 17.3195 8.53841 16.9289 8.92893C16.5384 9.31946 16.5384 9.95262 16.9289 10.3431L22.5858 16L16.9289 21.6569C16.5384 22.0474 16.5384 22.6805 16.9289 23.0711C17.3195 23.4616 17.9526 23.4616 18.3431 23.0711L24.7071 16.7071ZM8 17L24 17V15L8 15V17Z" fill="white"/>
                                            </svg>
                                            <p className="roboto text-[16px] xl:text-[20px] text-white"><Trans>{t(slide.title1)}</Trans></p>
                                        </div>
                                        <p className="mt-[20px] roboto text-[14px] xl:text-[16px] font-[300] text-[#FFFFFFB2]"><Trans>{t(slide.text1)}</Trans></p>
                                        <div className="w-full h-px bg-white bottom-0 absolute"></div>
                                    </div>
                                    <div className="h-[200px] relative mt-[20px] mx-auto">
                                        <div className="flex gap-[25px] items-center h-[60px]">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                <rect x="1" y="1" width="30" height="30" fill="#B30101" stroke="#B30101" stroke-width="2"/>
                                                <path d="M24.7071 16.7071C25.0976 16.3166 25.0976 15.6834 24.7071 15.2929L18.3431 8.92893C17.9526 8.53841 17.3195 8.53841 16.9289 8.92893C16.5384 9.31946 16.5384 9.95262 16.9289 10.3431L22.5858 16L16.9289 21.6569C16.5384 22.0474 16.5384 22.6805 16.9289 23.0711C17.3195 23.4616 17.9526 23.4616 18.3431 23.0711L24.7071 16.7071ZM8 17L24 17V15L8 15V17Z" fill="white"/>
                                            </svg>
                                            <p className="roboto text-[16px] xl:text-[20px] text-white"><Trans>{t(slide.title2)}</Trans></p>
                                        </div>
                                        <p className="mt-[20px] roboto text-[14px] xl:text-[16px] font-[300] text-[#FFFFFFB2]"><Trans>{t(slide.text2)}</Trans></p>
                                        <div className="w-full h-px bg-white bottom-0 absolute"></div>
                                    </div>
                                </div>
                            </SwiperSlide>  
                        ))}
                    </Swiper>
                    <div className="flex justify-between sm:justify-normal gap-[20px] xl:hidden">
                        <svg onClick={() => swiperInstance2 && swiperInstance2.slidePrev()} className="cursor-pointer w-[35px]" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                            <rect x="1" y="1" width="58" height="58" fill="black" stroke="white" stroke-width="2"/>
                            <path d="M14.2929 29.2929C13.9024 29.6834 13.9024 30.3166 14.2929 30.7071L20.6569 37.0711C21.0474 37.4616 21.6805 37.4616 22.0711 37.0711C22.4616 36.6805 22.4616 36.0474 22.0711 35.6569L16.4142 30L22.0711 24.3431C22.4616 23.9526 22.4616 23.3195 22.0711 22.9289C21.6805 22.5384 21.0474 22.5384 20.6569 22.9289L14.2929 29.2929ZM45 29L15 29L15 31L45 31L45 29Z" fill="white"/>
                        </svg>
                        <svg onClick={() => swiperInstance2 && swiperInstance2.slideNext()} className="cursor-pointer w-[35px]" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                            <rect x="1" y="1" width="58" height="58" fill="black" stroke="white" stroke-width="2"/>
                            <path d="M45.7071 30.7071C46.0976 30.3166 46.0976 29.6834 45.7071 29.2929L39.3431 22.9289C38.9526 22.5384 38.3195 22.5384 37.9289 22.9289C37.5384 23.3195 37.5384 23.9526 37.9289 24.3431L43.5858 30L37.9289 35.6569C37.5384 36.0474 37.5384 36.6805 37.9289 37.0711C38.3195 37.4616 38.9526 37.4616 39.3431 37.0711L45.7071 30.7071ZM15 31L45 31V29L15 29V31Z" fill="white"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;