import React, { useEffect } from "react";

import Main from '../components/Home/Main'
import Refer from "../components/Home/Refer";
import GroundBreaking from "../components/Home/GroundBreaking";
import Services from "../components/Home/Services";

function Home () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <Refer/>
            <GroundBreaking/>
            <Services/>
        </div>
    );
}

export default Home;