import React, { useEffect } from "react";

import Main from '../components/TradingTools/Main'
import WhatAreDaily from "../components/TradingTools/WhatAreDaily";
import Switcher from "../components/TradingTools/Switcher";
import Advantages from "../components/TradingTools/Advantages";

function TradingTools () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
            <Main/>
            <WhatAreDaily/>
            <Switcher/>
            <Advantages/>
        </div>
    );
}

export default TradingTools;