
import React from "react";

import { useTranslation, Trans } from "react-i18next";

import o1 from '../../img/AboutUs/o1.png'
import o2 from '../../img/AboutUs/o2.png'
import o3 from '../../img/AboutUs/o3.png'
import o4 from '../../img/AboutUs/o4.png'
import o5 from '../../img/AboutUs/o5.png'

function OurValues () {

    const { t } = useTranslation();
    
    return(
        <div className="relative overflow-hidden bg-black">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px] mt-[80px] xl:mt-[160px]">
                <div className="flex flex-col xl:flex-row justify-between">
                    <div className="flex flex-col justify-between max-w-[525px]">
                        <p className="text-[24px] xl:text-[48px] font-[500] manrope leading-[1.2] spanred text-white"><Trans>{t('aboutus.25')}</Trans></p>
                        <p className="roboto text-[16px] xl:text-[20px] text-white"><Trans>{t('aboutus.26')}</Trans></p>
                    </div>
                    <div className="max-w-[562px]">
                        <p className="text-[#FFFFFFB2] roboto font-[300] text-[14px] xl:text-[16px]"><Trans>{t('aboutus.27')}</Trans></p>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row pt-[40px] xl:pt-[80px]">
                    <div className="md:w-1/3 border-[2px] aspect-[1/1] md:mr-[-2px]">
                        <img src={o1} alt="" className="w-full h-full object-cover"/>
                    </div>
                    <div className="md:w-1/3 border-[2px] aspect-[1/1] mt-[-2px] md:mt-0 flex flex-col p-[40px] justify-between">
                        <p className="text-white roboto text-[16px] xl:text-[20px]">/01</p>
                        <p className="manrope text-white text-[16px] xl:text-[32px] leading-[1.2]">{t('aboutus.28')}</p>
                    </div>
                    <div className="md:w-1/3 border-[2px] aspect-[1/1] md:ml-[-2px] mt-[-2px] md:mt-0">
                        <img src={o2} alt="" className="w-full h-full object-cover"/>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row mt-[-2px]">
                    <div className="md:w-1/3 border-[2px] aspect-[1/1] mt-[-2px] md:mt-0 flex flex-col p-[40px] justify-between md:mr-[-2px]">
                        <p className="text-white roboto text-[16px] xl:text-[20px]">/02</p>
                        <p className="manrope text-white text-[16px] xl:text-[32px] leading-[1.2]">{t('aboutus.29')}</p>
                    </div>
                    <div className="md:w-1/3 border-[2px] aspect-[1/1] mt-[-2px] md:mt-0">
                        <img src={o3} alt="" className="w-full h-full object-cover"/>
                    </div>
                    <div className="md:w-1/3 border-[2px] aspect-[1/1] mt-[-2px] md:mt-0 flex flex-col p-[40px] justify-between md:ml-[-2px]">
                        <p className="text-white roboto text-[16px] xl:text-[20px]">/03</p>
                        <p className="manrope text-white text-[16px] xl:text-[32px] leading-[1.2]">{t('aboutus.30')}</p>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row mt-[-2px]">
                    <div className="md:w-1/3 border-[2px] aspect-[1/1] md:mr-[-2px]">
                        <img src={o4} alt="" className="w-full h-full object-cover"/>
                    </div>
                    <div className="md:w-1/3 border-[2px] aspect-[1/1] mt-[-2px] md:mt-0 flex flex-col p-[40px] justify-between">
                        <p className="text-white roboto text-[16px] xl:text-[20px]">/04</p>
                        <p className="manrope text-white text-[16px] xl:text-[32px] leading-[1.2]">{t('aboutus.31')}</p>
                    </div>
                    <div className="md:w-1/3 border-[2px] aspect-[1/1] md:ml-[-2px] mt-[-2px] md:mt-0">
                        <img src={o5} alt="" className="w-full h-full object-cover"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurValues;