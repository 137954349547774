import React, { useState } from "react";

import { useTranslation, Trans } from "react-i18next";

function Award () {

    const { t } = useTranslation();
    
    return(
        <div className="relative overflow-hidden pt-[80px] xl:pt-[160px] pb-[80px] xl:pb-[160px]">
            <div className="max-w-[1280px] xl:mx-auto mx-[20px]">
                <div className="flex flex-col xl:flex-row justify-between">
                    <p className="manrope leading-[1.2] font-[500] text-[24px] xl:text-[48px]">
                        {t('aboutus.5')}
                    </p>
                    <p className="roboto text-[16px] xl:text-[20px] text-[#000000B2] max-w-[489px] mt-[20px] xl:mt-[25px]">
                        {t('aboutus.6')}
                    </p>
                </div>
                <div className="flex flex-col xl:flex-row justify-between mt-[20px] xl:mt-[60px]">
                    <div className="xl:w-[600px] flex flex-col gap-[30px]">
                        <div className="flex flex-col xl:flex-row gap-[15px] xl:gap-[40px] pb-[15px] xl:pb-[30px] border-b xl:items-center border-[#000]">
                            <div className="w-[121px] h-[32px] bg-[#B30101] flex items-center justify-between px-[20px]">
                                <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                    <path d="M16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM0 9L16 9V7L0 7L0 9Z" fill="white"/>
                                </svg>
                                <p className="text-white manrope text-[16px] xl:text-[20px] font-[500] leading-[1.2]">2019</p>
                            </div>
                            <div>
                                <p className="manrope text-[20px] xl:text-[24px] manrope leading-[1.2]">{t('aboutus.7')}</p>
                                <p className="manrope text-[14px] xl:text-[16px] manrope font-[300] text-[#000000B2]">{t('aboutus.8')}</p>
                            </div>
                        </div>
                        <div className="flex flex-col xl:flex-row gap-[15px] xl:gap-[40px] pb-[15px] xl:pb-[30px] border-b xl:items-center border-[#000]">
                            <div className="w-[121px] h-[32px] bg-[#B30101] flex items-center justify-between px-[20px]">
                                <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                    <path d="M16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM0 9L16 9V7L0 7L0 9Z" fill="white"/>
                                </svg>
                                <p className="text-white manrope text-[16px] xl:text-[20px] font-[500] leading-[1.2]">2020</p>
                            </div>
                            <div>
                                <p className="manrope text-[20px] xl:text-[24px] manrope leading-[1.2]">{t('aboutus.9')}</p>
                                <p className="manrope text-[14px] xl:text-[16px] manrope font-[300] text-[#000000B2]">{t('aboutus.10')}</p>
                            </div>
                        </div>
                        <div className="flex flex-col xl:flex-row gap-[15px] xl:gap-[40px] pb-[15px] xl:pb-[30px] border-b xl:items-center border-[#000]">
                            <div className="w-[121px] h-[32px] bg-[#B30101] flex items-center justify-between px-[20px]">
                                <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                    <path d="M16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM0 9L16 9V7L0 7L0 9Z" fill="white"/>
                                </svg>
                                <p className="text-white manrope text-[16px] xl:text-[20px] font-[500] leading-[1.2]">2021</p>
                            </div>
                            <div>
                                <p className="manrope text-[20px] xl:text-[24px] manrope leading-[1.2]">{t('aboutus.11')}</p>
                                <p className="manrope text-[14px] xl:text-[16px] manrope font-[300] text-[#000000B2]">{t('aboutus.12')}</p>
                            </div>
                        </div>
                    </div>
                    <div className="xl:w-[600px] flex flex-col gap-[30px] mt-[20px] xl:mt-0">
                        <div className="flex flex-col xl:flex-row gap-[15px] xl:gap-[40px] pb-[15px] xl:pb-[30px] border-b xl:items-center border-[#000]">
                            <div className="w-[121px] h-[32px] bg-[#B30101] flex items-center justify-between px-[20px]">
                                <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                    <path d="M16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM0 9L16 9V7L0 7L0 9Z" fill="white"/>
                                </svg>
                                <p className="text-white manrope text-[16px] xl:text-[20px] font-[500] leading-[1.2]">2022</p>
                            </div>
                            <div>
                                <p className="manrope text-[20px] xl:text-[24px] manrope leading-[1.2]">{t('aboutus.13')}</p>
                                <p className="manrope text-[14px] xl:text-[16px] manrope font-[300] text-[#000000B2]">{t('aboutus.14')}</p>
                            </div>
                        </div>
                        <div className="flex flex-col xl:flex-row gap-[15px] xl:gap-[40px] pb-[15px] xl:pb-[30px] border-b xl:items-center border-[#000]">
                            <div className="w-[121px] h-[32px] bg-[#B30101] flex items-center justify-between px-[20px]">
                                <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                    <path d="M16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM0 9L16 9V7L0 7L0 9Z" fill="white"/>
                                </svg>
                                <p className="text-white manrope text-[16px] xl:text-[20px] font-[500] leading-[1.2]">2023</p>
                            </div>
                            <div>
                                <p className="manrope text-[20px] xl:text-[24px] manrope leading-[1.2]">{t('aboutus.15')}</p>
                                <p className="manrope text-[14px] xl:text-[16px] manrope font-[300] text-[#000000B2]">{t('aboutus.16')}</p>
                            </div>
                        </div>
                        <div className="flex flex-col xl:flex-row gap-[15px] xl:gap-[40px] pb-[15px] xl:pb-[30px] border-b xl:items-center border-[#000]">
                            <div className="w-[121px] h-[32px] bg-[#B30101] flex items-center justify-between px-[20px]">
                                <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                    <path d="M16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM0 9L16 9V7L0 7L0 9Z" fill="white"/>
                                </svg>
                                <p className="text-white manrope text-[16px] xl:text-[20px] font-[500] leading-[1.2]">2024</p>
                            </div>
                            <div>
                                <p className="manrope text-[20px] xl:text-[24px] manrope leading-[1.2]">{t('aboutus.17')}</p>
                                <p className="manrope text-[14px] xl:text-[16px] manrope font-[300] text-[#000000B2]">{t('aboutus.18')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-[1280px] mx-[20px] xl:mx-auto flex flex-col md:flex-row justify-between pt-[80px] xl:pt-[160px] gap-[10px]">
                <div className="relative xl:w-[393px] h-[382px] xl:h-[547px] bg-[#ECECEC] overflow-hidden p-[40px] flex flex-col justify-end">
                    <p className="text-[200px] xl:text-[300px] absolute top-[-60px] left-[-50px] xl:top-[-90px] xl:left-[-50px] leading-[1] text-[#B30101] manrope font-[800] tracking-[-15px]">01</p>
                    <p className="text-[20px] xl:text-[32px] manrope leading-[1.2] text-[#000000]">{t('aboutus.19')}</p>
                    <p className="text-[14px] xl:text-[16px] roboto leading-[1.5] text-[#000000B2] font-[300] mt-[20px]">{t('aboutus.20')}</p>
                </div>
                <div className="relative xl:w-[393px] h-[382px] xl:h-[547px] bg-[#ECECEC] overflow-hidden p-[40px] flex flex-col">
                    <p className="text-[200px] xl:text-[300px] absolute bottom-[-60px] left-[-50px] xl: xl:bottom-[-90px] xl:left-[-50px] leading-[1] text-[#B30101] manrope font-[800] tracking-[-15px]">02</p>
                    <p className="text-[20px] xl:text-[32px] manrope leading-[1.2] text-[#000000]">{t('aboutus.21')}</p>
                    <p className="text-[14px] xl:text-[16px] roboto leading-[1.5] text-[#000000B2] font-[300] mt-[20px]">{t('aboutus.22')}</p>
                </div>
                <div className="relative xl:w-[393px] h-[382px] xl:h-[547px] bg-[#ECECEC] overflow-hidden p-[40px] flex flex-col justify-end">
                    <p className="text-[200px] xl:text-[300px] absolute top-[-60px] left-[-50px] xl:top-[-90px] xl:left-[-50px] leading-[1] text-[#B30101] manrope font-[800] tracking-[-15px]">03</p>
                    <p className="text-[20px] xl:text-[32px] manrope leading-[1.2] text-[#000000]">{t('aboutus.23')}</p>
                    <p className="text-[14px] xl:text-[16px] roboto leading-[1.5] text-[#000000B2] font-[300] mt-[20px]">{t('aboutus.24')}</p>
                </div>
            </div>
        </div>
    );
}

export default Award;